<template>
    <div class="page-main">
        <a-card style="margin-bottom:20px">
            <a-form :form="searchForm"
                layout="inline">
                <a-form-item label="归属员工">
                    <a-select
                        show-search
                        style="width: 200px"
                        v-model="searchForm._id"
                        placeholder="请选择"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="handleSelectSearch"
                        >
                        <a-select-option v-for="(item,index) in staffList" :key="index" :value="item._id">
                            {{ item.username }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="标签" >
                    <a-select mode="multiple" v-model="searchForm.label_id" style="width: 200px">
                        <a-select-opt-group v-for="item in tagTree" :key="item.id">
                            <span slot="label"><a-icon type="dash" />{{item.name}}</span>
                            <a-select-option v-for="obj in item.children" :key="obj.id" :value="obj.id">
                                {{obj.name}}
                            </a-select-option>
                        </a-select-opt-group>
                    </a-select>
                </a-form-item>
                <a-form-item >
                <a-button type="primary"
                            icon="search"
                            @click="handleClickSearch">
                    搜索
                </a-button>
                </a-form-item>
                <a-form-item >
                <a-button 
                            icon="reload"
                            @click="resetSearchForm">
                    重置
                </a-button>
                </a-form-item>
            </a-form>
        </a-card>
        
       <a-card>
            <!-- <a-row style="text-align:right;margin-bottom:20px;">
                <a-button type="primary" icon="plus" @click="handleAddAutomatic">添加</a-button>
            </a-row> -->
            <a-table 
                :columns="customerColumns" 
                :data-source="customerData"
                :row-key="record => record._id"
                :pagination="pagination"
                @change="handleTableChange"
            >
                <template slot="time" slot-scope="text">{{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}</template>
                <template slot="tag" slot-scope="arr">
                    <span v-for="item in arr" :key="item">
                        <a-tag color="blue" v-if="formatTag(item)">{{formatTag(item)}}</a-tag>
                    </span>
                    
                </template>
                <span slot="sex" slot-scope="text">{{text===1?'男':text===2?'女':'未知'}}</span>
                <div class="account-view" slot="username" slot-scope="text,record">
                    <a-avatar :src="record.avatar" />
                    <div class="text">
                        {{record.username}}
                    </div>
                </div>
                <template slot="action" slot-scope="text,record">
                    <div class="operation-wrapper">
                        <a-button type="link" @click="handleClickDetail(record)">详情</a-button>
                        <a-button type="link" @click="addTag(record)">打标签</a-button>
                    </div>  
                </template>
            </a-table>
        </a-card>
        <a-modal v-model="shareVisble" title="共享客户" @ok="handleShareSubmit">
            <a-form >
                <a-form-item 
                    label="客户名" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input v-model="detailObj.username" :disabled="true" />
                </a-form-item>
                 <a-form-item 
                    label="业务员" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                   <a-select
                        mode="multiple"
                        v-model="shareObj.share_list"
                        placeholder="请选择"
                    >
                        <a-select-option v-for="d in robotList" :key="d._id">
                            <a-avatar size="small" :src="d.avatar" style="margin-right:10px"/>{{ d.username }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="visible" title="新增标签" @ok="handleSubmit">
            <a-form  :form="updateUser">
                <a-form-item 
                    label="客户名" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input v-model="updateUser.username" :disabled="true" />
                </a-form-item>
                <a-form-item 
                    label="客户标签" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                   <a-tree-select
                        v-model="arrList"
                        style="width: 100%"
                        :tree-data="tagTree"
                        tree-checkable
                        :show-checked-strategy="SHOW_PARENT"
                        placeholder="请选择"
                        :replaceFields="{title:'name',key:'id',value:'id'}"
                        @change="getSeleted"
                    />
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="detailVisible" title="详情" :footer="null" width="700px">
            <div class="detail-view">
                <a-row class="detail-row">
                    <a-col class="label" :span="6">客户名称:</a-col>
                    <a-col class="value" :span="14">{{detailObj.username}}</a-col>
                </a-row>
                <a-row class="detail-row">
                    <a-col class="label" :span="6">性别:</a-col>
                    <a-col class="value" :span="14">
                        <span>{{detailObj.sex===1?'男':detailObj.sex===2?'女':'未知'}}</span>
                    </a-col>
                </a-row>
                <a-row class="detail-row">
                    <a-col class="label" :span="6">客户标签:</a-col>
                    <a-col class="value" :span="14" v-if="detailObj.label">
                        <span v-for="item in detailObj.label" :key="item">
                            <a-tag color="blue" v-if="formatTag(item)">{{formatTag(item)}}</a-tag>
                        </span>
                    </a-col>
                    <a-col v-else class="value" :span="14">
                         未设置
                    </a-col>
                </a-row>
                <a-row class="dd-row">
                    <a-col class="label" :span="4"></a-col>
                    <a-col class="value" :span="16">
                        <a-table 
                            :columns="staffColumns" 
                            :data-source="userList"
                            :row-key="record => record._id"
                            :pagination="false"
                        >
                            <template slot="time" slot-scope="text">{{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}</template>
                            <div class="account-view" slot="username" slot-scope="text,record">
                                <a-avatar :src="record.avatar" />
                                <div class="text">
                                    {{record.username}}
                                </div>
                            </div>
                            <template slot="action" slot-scope="text,record">
                                <div class="operation-wrapper">
                                    <a-button type="link" @click="handleShare(record)">共享</a-button>
                                </div>  
                            </template>
                            
                        </a-table>
                    </a-col>
                    <!-- <a-col class="label" :span="4"></a-col> -->
                </a-row>
            </div>
        </a-modal>
    </div>
</template>
<script>
const customerColumns = [
    {
        title: '客户',
        dataIndex: 'username',
        align:'center',
        scopedSlots: { customRender: 'username' },
    },
    {
        title: '性别',
        dataIndex: 'sex',
        align:'center',
        scopedSlots: { customRender: 'sex' },
    },
    {
        title: '个人标签',
        dataIndex: 'label',
        align:'center',
        scopedSlots: { customRender: 'tag' },
    },
    {
        title: "操作",
        width: 120,
        align: "center",
        ellipsis: true,
        scopedSlots: { customRender: "action" },
    }
]
const staffColumns = [
    {
        title: '员工',
        dataIndex: 'username',
        align:'center',
        scopedSlots: { customRender: 'username' },
    },
    {
        title: '建立好友时间',
        dataIndex: 'create_time',
        align:'center',
        scopedSlots: { customRender: 'time' },
    },
    {
        title: "操作",
        width: 120,
        align: "center",
        ellipsis: true,
        scopedSlots: { customRender: "action" },
    }
]
import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
import {
    mapState
  } from 'vuex'
export default {
    name:'customer-manage',
    data(){
        return{
            SHOW_PARENT,
            staffColumns,
            visible:false,
            detailVisible:false,
            shareVisble:false,
            searchForm:{
                _id:'',
                label_id:[]
            },
            customerData:[],
            arrList:[],
            staffList:[],
            userList:[],
            robotList:[],
            customerColumns,
            updateUser:{},
            shareObj:{},
            detailObj:{},
            pagination: {
                total: 1,
                current: 1,
                pageSize: 10, //每页中显示10条数据
                showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
            },
        }
    },
    computed: {
        ...mapState(['mqttInit','tagList','tagTree','robots'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleSearch()
                    this.getEntrustList()
                }
            },
            immediate:true
        }
    },
    methods:{
        handleShare(row){
            this.handleGetRobots(row.user_id)
            this.shareObj = {...row}
            this.shareVisble = true
        },
        handleGetRobots(id){
            this.robotList = this.robots.filter(item=>item._id!==id)
        },
        handleTableChange(pagination){
            this.pagination = pagination
            this.handleSearch()
        },
        handleClickDetail(row){
            this.handleGetStaff(row)
            this.detailObj = row
            this.detailVisible = true
        },
        handleGetStaff(row){
             let payload = {
                msgId:'searchStaff',
                data:{
                    friend_user_id:row.friend_user_id
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/searchStaff",
                payload,
                },
                  (topic, data) => {
                      if(data.success){
                          this.userList = data.res
                      }
                },
                this
            );
        },
        handleShareSubmit(){
            if(!this.shareObj.share_list || this.shareObj.share_list.length==0){
                this.$message.error('请先选择要分享的业务员')
                return
            }
            let payload = {
                msgId:'saveShare',
                data:{
                    friend_user_id:this.shareObj.friend_user_id,
                    user_id:this.shareObj.user_id,
                    share_list:this.shareObj.share_list
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/share/save",
                payload,
                },
                  (topic, data) => {
                      if(data.success){
                          this.$message.success('共享成功')
                          this.shareVisble = false
                          this.handleGetStaff(this.detailObj)
                      }
                },
                this
            );
        },
        handleSubmit(){
            this.visible = false
            this.$message.success('新增成功')
            setTimeout(()=>{
                this.handleSearch()
            },2000)
            let payload = {
                msgId:'setLabel',
                data:{
                    user_id:this.updateUser.friend_user_id,
                    label_list:this.updateUser.label
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/robot/setLabel",
                payload,
                },
                  (topic, data) => {
                      if(data.sucess){
                        
                      }
                },
                this
            );
        },
        addTag(item){
            this.updateUser = {...item}
            this.arrList = item.label
            this.visible =true
        },
        handleClickSearch(){
            this.pagination.current = 1
            this.handleSearch()
        },
        handleSearch(){
            let payload = {
                msgId:'searchFriendsByPage',
                data:{
                    pageSize:this.pagination.pageSize,
                    pageNum:this.pagination.current
                }
            }
            let {_id,label_id} = this.searchForm
            if(_id){
                payload.data._id = _id
            }
            if(label_id.length>0){
                payload.data.label_id = label_id
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/searchFriends",
                payload,
                },
                  (topic, data) => {
                    //   console.log(data)
                    this.customerData = data.res
                    this.pagination.total = data.count
                },
                this
            );
        },
        formatTag(id){
            let obj = this.tagList.filter(item=>item.label_id == id)
            return obj[0]?obj[0].label_name:''
        },
        resetSearchForm(){
            this.searchForm = {_id:'',label_id:[]}
        },
        getSeleted(){
            if(this.arrList.length==0){
                return
            }
            let arr = []
            this.arrList.forEach(item=>{
                let index = this.tagTree.findIndex(obj=>obj.id==item)
                if(index!==-1){
                    this.tagTree[index].children.forEach(k=>arr.push(k.id))
                }else{
                    arr.push(item)
                }
            })
            this.updateUser.label = arr
        },
         //获取业务列表
        getEntrustList(name=''){
            let payload = {
                msgId:'userlist',
                data: {
                    username:name,
                    pageSize:100,
                    pageNum:1
                },
            };
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/weUser/searchUserByPage",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.staffList = data.data.res
                    }
                },
                this
            );
        },
        handleSelectSearch(val){
            this.getEntrustList(val)
        },
        onChange(){

        }
    }
}
</script>
<style lang="scss" scoped>
// .detail-view{
    // max-height: 350px;
    // overflow: scroll;
// }
.detail-view::-webkit-scrollbar{display:none;}
.detail-row{
    height: 60px;
    .label{
        text-align: right;
        margin-right: 40px;
    }
}
.dd-row{
    .label{
        text-align: right;
    }
}
.operation-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
    .account-view{
        display: flex;
        align-items: center;
        justify-content: center;
        .text{
            margin-left: 5px;
        }
    // justify-content: center;
    }
</style>